import { unrefElement, useEventListener } from '@vueuse/core'

const defaultWindow = typeof window !== 'undefined' ? window : undefined

/**
 * Listen for clicks outside of an element.
 *
 * @see https://vueuse.org/onClickOutside
 * @param target
 * @param handler
 * @param options
 */
export function onClickOutside(
  target,
  handler,
  options = {},
) {
  const { window = defaultWindow, event = 'pointerdown' } = options

  if (!window)
    return

  const listener = (event) => {
    const el = unrefElement(target)

    if (!el || el === event.target || eventPath(event).includes(el))
      return

    handler(event)
  }

  function eventPath(event) {
    const path = (event.composedPath && event.composedPath()) || event.path

    if (path != null)
      return path

    function getParents(node, memo = []) {
      const parentNode = node.parentNode

      return parentNode
        ? getParents(parentNode, memo.concat([parentNode]))
        : memo
    }

    return [event.target].concat(getParents(event.target))
  }

  return useEventListener(window, event, listener, { passive: true })
}