<template>
  <div>
    <div class="w-full h-screen overflow-hidden relative">
      <NuxtLink
        :to="ilustratedPage?.link_back_button || '/'"
        v-if="ilustratedPage?.title_back_button"
        class=" m-4 lg:px-16 lg:pt-8 absolute flex items-center lg:gap-2"
      >
        <LucideArrowLeft class=" w-4 h-4 lg:w-6 lg:h-6" />
        <typography size="main-l">{{
          ilustratedPage?.title_back_button
        }}</typography>
      </NuxtLink>
      <img
        ref="image"
        :src="ilustratedPage?.image"
        class="w-full h-screen object-cover"
        @load="calculatePosition"
      />
      <span
        v-for="(pixel, index) in clickablePixels"
        :key="index"
        :style="{ left: pixel.x + 'px', top: pixel.y + 'px' }"
        class="flex items-center justify-center absolute"
      >
        <Typography
          size="main-l"
          class="absolute bottom-0 mb-4 text-shadow-lg children:text-black"
          >{{ pixel.title }}</Typography
        >
        <div
          class="animate-ping w-4 h-4 md:w-6 md:h-6 bg-primary absolute rounded-full"
        ></div>
        <div
          @click="() => handleClickablePixel(pixel)"
          class="w-4 h-4 md:w-6 md:h-6 bg-primary absolute cursor-pointer rounded-full"
        ></div>
      </span>
      <span
        v-for="(object, index) in clickableObjects"
        :key="index"
        :style="{ left: object.x + 'px', top: object.y + 'px' }"
        class="flex items-center justify-center absolute"
      >
        <div
          class="cursor-pointer"
          @click="(event) => handleClickableObject(object, event)">
          <img :src="assetsUrl + '/' + object?.image"
               class="clickable-object__image" />
        </div>
      </span>
      <!--- interactive event modal --->
      <div
        class="fixed inset-0 bg-graybase bg-opacity-50 flex items-center justify-center"
        v-if="selectedClickableObject"
      >
        <div
          class="md:absolute objectmodal lg: bg-graybase border-2 border-primary border-solid max-w-[350px] h-80 overflow-y-scroll md:overflow-auto lg:max-w-[450px] lg:h-auto"
          ref="objectModal"
          :style="{
            left: `${cursorModalPosition.x}px`,
            top: `${cursorModalPosition.y}px`,
          }">
          <img
            :src="selectedClickableObject.contentImage"
            class="w-full aspect-[16/9] object-cover"
          />
          <div class="flex flex-col gap-2 pl-6 pr-6 pb-4 pt-4">
            <Typography
              size="main-l"
              class="text-white leading-none text-nowrap"
              >{{ selectedClickableObject.title }}</Typography
            >
            <div
              v-html="selectedClickableObject.content"
              class="text-white flex flex-col gap-2 z-10 leading-5 mb-4"
            ></div>
            <button
              v-if="selectedClickableObject.badge_id && !selectedClickableObject.userHasBadge"
              class="py-2 w-full bg-primary font-bold text-2xl text-center"
              :class="{
                'cursor-not-allowed':
                  selectedClickableObject.userHasBadge || disabled,
                'opacity-50': selectedClickableObject.userHasBadge || disabled,
              }"
              :disabled="selectedClickableObject.userHasBadge || disabled"
              @click="handleClaimBadge"
            >
              <Typography size="main-l">{{
                selectedClickableObject.userHasBadge ? "Claimed" : "Claim Badge"
              }}</Typography>
            </button>
            <a
              v-if="(!selectedClickableObject.badge_id && selectedClickableObject.visit)
                     || (selectedClickableObject.userHasBadge && selectedClickableObject.visit)"
              class="py-2 w-full bg-primary font-bold text-2xl text-center"
              :href="selectedClickableObject.visit"
              target="_blank">
              <Typography size="main-l">{{selectedClickableObject.visit_cta}}</Typography>
            </a>
            <div v-if="selectedClickableObject.userHasBadge" class="flex justify-center justify-items-center gap-2 mt-2">
              <img src="~/assets/svg/claimed.svg" alt="woods" class="h-5 w-5" />
              <Typography
                size="secondary-sm"
                class="text-gray-300 text-center">Badge Claimed</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlbarraModal />
    <MobileFullModal />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import Typography from "@/components/common/Typography.vue";
import { useRoute } from "vue-router";
import AlbarraModal from "~/components/modals/AlbarraModal.vue";
import MobileFullModal from "../components/mobile-full-modal.vue";
import { cmsStore } from "../stores/cmsStore";
import { useModalsStore } from "~/stores/useModalsStore";
import { useAuthStore } from "../stores/useAuthStore";
import { onClickOutside } from "../composables/onClickOutside";
import { useLoaderStore } from "../stores/useLoaderStore";

const assetsUrl = useRuntimeConfig().public.assetsUrl;

const loaderStore = useLoaderStore();
const modalsStore = useModalsStore();
const auth = useAuthStore();
const cms = cmsStore();
const route = useRoute();
const modalIsOpen = ref(false);
const modalId = ref(null);
const ilustratedPage = ref(null);
const image = ref(null);
const clickablePixels = ref([]);
const clickableObjects = ref([]);
const selectedClickableObject = ref(null);
const userBadges = ref([]);
const disabled = ref(false);
const objectModal = ref(null);
loaderStore.setLoading(true);

onClickOutside(objectModal, (event) => {
  console.log("click outside");
  selectedClickableObject.value = null;
});

function isDayOrNight() {
  const currentHour = new Date().getHours();
  if (currentHour >= 6 && currentHour < 18) {
    return "day";
  } else {
    return "night";
  }
}

const handleClickablePixel = (pixel) => {
  if (pixel.link === null) return;
  if (pixel.link.includes("http://") || pixel.link.includes("https://")) {
    return window.open(pixel.link, "_blank");
  }
  return navigateTo(pixel.link);
};

const handleClickableObject = (object, event) => {
  selectedClickableObject.value = object;
  showMenu(event);
};

const handleClaimBadge = () => {
  disabled.value = true;
  if (!auth.isAuthenticated) {
    modalsStore.openModal("LOGIN");
    disabled.value = false;
    return;
  }
  if (!auth.user.directusUser.discord_id) {
    modalsStore.openModal("JOIN_DISCORD");
    const path = window.location.pathname;
    localStorage.setItem('fromPage', path);
    cms.setFromPage(path);
    disabled.value = false;
    return;
  }
  const badgeId = selectedClickableObject.value.badge_id.id;
  if (!badgeId) return;
  cms
    .userHasBadge(badgeId)
    .then((response) => {
      if (response.code === "success-badge-assigned-now") {
        modalsStore.closeModal();
        modalsStore.openModal("CLAIM_BADGE", {
          badge: selectedClickableObject.value.badge_id,
          title: selectedClickableObject.value.title,
          succes_message: selectedClickableObject.value.succes_message,
          visit: selectedClickableObject.value.visit,
          visit_cta: selectedClickableObject.value.visit_cta,
        });
      }
    })
    .then(loadIllustratedPage)
    .finally(() => {
      disabled.value = false;
    });
};

const calculatePosition = () => {
  if (!image.value) return;
  const isMobile = isMobileLandscape()
  const { width, height } = image.value.getBoundingClientRect();
  clickablePixels.value.forEach((pixel) => {
    pixel.x = (pixel.x_axis / 100) * width;
    pixel.y = (pixel.y_axis / 100) * height + 4;
  });
  clickableObjects.value.forEach((object) => {
    if (isMobile && object.x_axis_mobile && object.y_axis_mobile) {
      object.x = (object.x_axis_mobile / 100) * width;
      object.y = (object.y_axis_mobile / 100) * height + 4;
    } else {
      object.x = (object.x_axis / 100) * width;
      object.y = (object.y_axis / 100) * height + 4;
    }
  })
}

function isMobileLandscape() {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent)
  const isLandscape = window.innerWidth > window.innerHeight
  
  return isMobile && isLandscape;
}

const loadIllustratedPage = async () => {
  const params = {
    filter: {
      slug: route.params.slug,
    },
  };
  const result = await cms.get("illustrated_page", params);
  ilustratedPage.value = result.data[0];
  ilustratedPage.value.image = `${assetsUrl}/${ilustratedPage.value.image}`;
  if (ilustratedPage.value.imageNight) {
    if (isDayOrNight() === "night") {
      ilustratedPage.value.image = `${assetsUrl}/${ilustratedPage.value.imageNight}`;
    }
  }
  await loadClickablePixels();
  await loadClickableObjects();
  if (auth.isAuthenticated) {
    await loadUserBadges();
    matchUserBadges();
  }
};

const loadClickablePixels = async () => {
  const params = {
    filter: {
      illustrated_page_id: ilustratedPage.value.id,
    },
  };
  const result = await cms.get("clickable_pixel", params);
  clickablePixels.value = result.data;
  calculatePosition();
};

const loadClickableObjects = async () => {
  const params = {
    filter: {
      illustrated_page_id: ilustratedPage.value.id,
    },
    fields: "*,badge_id.id,badge_id.title,badge_id.image",
  };
  const result = await cms.get("clickable_object", params);
  clickableObjects.value = result.data;

  clickableObjects.value.forEach((object) => {
    if (!object.contentImage) {
      object.contentImage = `https://placehold.co/500x200`;
    } else {
      object.contentImage = `${assetsUrl}/${object.contentImage}`;
    }
  });
  calculatePosition();
};

const loadUserBadges = async () => {
  const params = {
    filter: {
      user_id: auth.user.directusUser.id,
    },
  };
  const badgesItems = await cms.get("badges_directus_users", params);
  userBadges.value = badgesItems.data;
};

const matchUserBadges = () => {
  clickableObjects.value = clickableObjects.value.map((object) => {
    const userHasBadge = userBadges.value.find(
      (userBadge) => object.badge_id && userBadge.badge_id === object.badge_id.id
    );
    if (userHasBadge) {
      object.userHasBadge = true;
    }
    return object;
  });
};


onMounted(() => {
  loadIllustratedPage().finally();
  window.addEventListener("resize", calculatePosition);
  loaderStore.setLoading(false);
});

onUnmounted(() => {
  window.removeEventListener("resize", calculatePosition);
});

//contextmodal

const cursorModalVisible = ref(false);
const cursorModalPosition = ref({ x: 0, y: 0 });
const menu = ref(null);

const showMenu = async (event) => {
  event.preventDefault();

  await nextTick();

  setTimeout(() => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const menuWidth = objectModal.value.offsetWidth
    const menuHeight = objectModal.value.offsetHeight

    let posX = event.clientX
    let posY = event.clientY

    if (windowWidth - posX < menuWidth) {
      posX = event.clientX - menuWidth
    }

    if (posY + menuHeight > windowHeight) {
      posY = windowHeight - menuHeight - 24
    }

    cursorModalPosition.value = { x: posX, y: posY }
  }, 350)
}





definePageMeta({
  pageTransition: {
    name: "slide",
    mode: "out-in",
  },
});
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s;
}
.slide-enter-from {
  opacity: 0;
}
.slide-leave-to {
  opacity: 0;
}

.objectmodal {
  animation: scaleModal 0.6s alternate;
}

.clickable-object__image {
  width: 60%;
}

.clickable-object__image--debug {
  border: 1px solid red;
}

@keyframes scaleModal {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 1024px) {
  .objectmodal {
    top: auto !important;
    left: auto !important;
  }
}
@media (min-width: 1024px) {
  .clickable-object__image {
    width: 100%;
  }
}
</style>
  